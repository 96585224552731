
import { Vue, Options } from "vue-class-component";
import SearchTransactions from "../../components/SearchTransactions.vue";
import PrintTransactions from "../../components/PrintTransactions.vue";
import CheckoutReceipt from "../../components/CheckoutReceipt.vue";
import ReceiptHistory from "../../components/ReceiptHistory.vue";
import RedoReceipt from "../../components/RedoReceipt.vue";
import BuyBack from "../../components/BuyBack.vue";
import RefundReceipt from "../../components/RefundReceipt.vue";
import moment from "moment";
import { useStore, ActionTypes } from "../../store";
import Transaction from "../../service/Transaction";
import Toaster from "../../helpers/Toaster";
import { camelCase } from "lodash";
import PaymentScreen from "../../components/PaymentScreen.vue";
import ReceiptPayment from "../../components/ReceiptPayment.vue";
import CustomerDialog from "../../components/CustomerDialog.vue";

@Options({
  components: {
    SearchTransactions,
    PrintTransactions,
    CheckoutReceipt,
    ReceiptHistory,
    RedoReceipt,
    RefundReceipt,
    PaymentScreen,
    ReceiptPayment,
    BuyBack,
    CustomerDialog,
  },
})
export default class Transactions extends Vue {
  private store = useStore();
  private dialogTilte = " Checkout Payments";
  private componentName = "SearchTransactions";
  private checkoutConfirmDialog = false;
  private signatureDialog = false;
  private paymentDialog = false;
  private cancelDialog = false;
  private confirmEmail = false;
  private confirmCloseScreen = false;
  private printDialog = false;
  private txnService;
  private toast;
  private activePage = "search";
  private date1 = "";
  private date2 = "";
  private receiptID = "";
  private totalBalance = 0;
  private customerID = "";
  private customerName = "";
  private CustomerNeedles = 0;
  private customerOrders = [
    {
      cusId: "",
      date: "",
      dueDate: "",
      employeeId: "",
      firstName: "",
      id: "",
      invoicePayment: [],
      lastName: "",
      notificationStatus: "",
      status: "",
      totalBill: 0,
      totalPaid: 0,
      totalQty: 0,
    },
  ];
  private receiptItems = [
    {
      checkStatus: "",
      id: "",
      invId: "",
      qty: 1,
      serviceName: "",
      workingStatus: "",
    },
  ];
  private checkedItems: string[] = [];
  private customerId = "";
  private customerStatus = "";
  private permission = "";
  private dialogTitle = "";

  //DEFAULT METHOD OF TYPE SCRIPT
  //CALLING WHENEVER COMPONENT LOADS
  created() {
    this.txnService = new Transaction();
    this.toast = new Toaster();
  }

  //USED TO UPDATE THE PIN STATUS
  updateCustomerStatus() {
    this.customerStatus = "";
  }

  openCustomerProfile(id) {
    this.dialogTitle = "Customer Profile";
    this.customerId = id;
    this.permission = "no";
    this.customerStatus = "updateCustomer";
  }

  togglePage(compName) {
    if (this.receiptID != "") {
      this.componentName = compName;
    } else {
      this.toast.showInfo("Please choose any order");
    }
  }

  confirmCheckOut() {
    this.checkoutConfirmDialog = true;
  }

  get pageStatus() {
    return this.activePage;
  }

  searchReceipt(res) {
    this.date1 = res.date1;
    this.date2 = res.date2;
    this.customerOrders = this.camelizeKeys(res.customer_orders);
  }

  formatDate(value) {
    if (value) {
      return moment(String(value)).format("DD-MM-YYYY");
    }
  }

  formatTime(value) {
    if (value) {
      const time = moment.duration(value);
      return moment(String(time), "HH:mm").format("hh:mm A");
    }
  }

  formatAmount(value) {
    let balance = 0;

    if (value != "") {
      if (value < 0.03) {
        value = 0;
      }

      balance = value.toFixed(2);
    }

    return balance;
  }

  paymentMethod(method) {
    let paymentMethod = "";

    if (method.length > 1) {
      paymentMethod = "Split";
    } else if (method.length == 1) {
      paymentMethod = method[0].paymentType;
    } else {
      paymentMethod = "Pay Later";
    }

    return paymentMethod;
  }

  getInvDetails(receiptID) {
    this.store.dispatch(ActionTypes.GET_RECEIPT_ID, receiptID);

    this.receiptID = receiptID;

    this.txnService.getInvReceipts(receiptID).then((data) => {
      const receiptItems = this.camelizeKeys(data["sales_order_items"]);
      this.receiptItems = receiptItems;
    });

    //previewReceipt
    this.componentName = "CheckoutReceipt";

    //MAKE LIST EMPTY
    this.checkedItems = [];
  }

  cancelReceipt() {
    if (this.receiptID != "") {
      this.cancelDialog = true;
    } else {
      this.toast.showInfo("Please choose any order");
    }
  }

  showPrintReceipt() {
    if (this.receiptID != "") {
      this.printDialog = true;
    } else {
      this.toast.showInfo("Please choose any order");
    }
  }

  cancelTheConfirmedReceipt() {
    this.cancelDialog = false;

    this.txnService.cancelReceipt(this.receiptID).then((res) => {
      this.toast.handleResponse(res);
      this.clearCheckOutScreen();
    });
  }

  generateReceipt(source) {
    this.printDialog = false;

    this.txnService.generateReceipt(source, this.receiptID).then((res) => {
      if (res == "success") {
        this.toast.showSuccess("Receipt Generated Successfully");
      } else {
        this.toast.showSuccess("Cannot find the Printer");
      }
    });
  }

  redoReceipt(obj) {
    if (this.checkedItems.length > 0) {
      this.txnService
        .redoReceipt(obj, this.checkedItems, this.receiptID)
        .then((res) => {
          this.toast.handleResponse(res);
          this.clearCheckOutScreen();
        });
    } else {
      this.toast.showWarning("Please choose the services to redo");
    }
  }

  clearCheckOutScreen() {
    this.customerOrders = [];
    this.receiptItems = [];
    this.date1 = "";
    this.date2 = "";
    this.componentName = "SearchTransactions";
  }

  closePaymentScreen() {
    this.paymentDialog = false;
  }

  validateCheckout() {
    let readyItems = 0;

    if (this.checkedItems.length > 0) {
      this.checkedItems.forEach((e) => {
        this.receiptItems.forEach((f) => {
          //CHECK IF STATUS IS READY AND IN OTHERWISE DONT ALLOW
          if (
            e == f.id &&
            f.workingStatus.toUpperCase() == "READY" &&
            f.checkStatus.toUpperCase() == "IN"
          ) {
            readyItems++;
          }
        });
      });

      if (readyItems == this.checkedItems.length) {
        this.checkoutConfirmDialog = false;

        //CALCULATE RECEIPT BALANCE AND SET CUSTOMER ID AND NAME
        const amountBal = this.checkReceiptBalance();

        if (amountBal == 0) {
          this.checkoutReceiptPayment([]);
        } else {
          this.txnService.getCustomerNeedles(this.customerID).then((res) => {
            this.CustomerNeedles = Number(res);
          });

          this.txnService.checkChargeAccount(this.receiptID).then((res) => {
            if (res == "Charge") {
              this.toast.showInfo("Customer is Charge Account");
              this.checkoutReceiptPayment([]);
            } else {
              //OPEN PAYMENT SCREEN
              this.paymentDialog = true;
            }
          });
        }
      } else {
        this.checkoutConfirmDialog = false;
        this.toast.showWarning(
          "Receipt items should be READY AND Status should be IN to checkout"
        );
      }
    } else {
      this.checkoutConfirmDialog = false;
      this.toast.showWarning("Please choose any receipt item");
    }
  }

  //SEND CHECKOUT REQUEST
  checkoutReceiptPayment(paymentList) {
    this.confirmCloseScreen = true;
    this.paymentDialog = false;

    this.txnService
      .checkoutReceipt(
        paymentList,
        this.checkedItems,
        this.receiptID,
        this.totalBalance
      )
      .then((res) => {
        this.toast.handleResponse(res);
        this.clearCheckOutScreen();
      });
  }

  checkReceiptBalance() {
    let balanceAmount = 0;

    this.customerOrders.forEach((e) => {
      if (e.id == this.receiptID) {
        this.customerID = e.cusId;
        this.customerName = e.firstName + " " + e.lastName;
        const balance = Number(e.totalBill) - Number(e.totalPaid);

        if (balance <= 0.03) {
          balanceAmount = 0;
        } else {
          balanceAmount = balance;
        }

        this.totalBalance = Number(balanceAmount);
      }
    });
    return balanceAmount;
  }

  camelizeKeys = (obj) => {
    if (Array.isArray(obj)) {
      return obj.map((v) => this.camelizeKeys(v));
    } else if (obj !== null && obj.constructor === Object) {
      return Object.keys(obj).reduce(
        (result, key) => ({
          ...result,
          [camelCase(key)]: this.camelizeKeys(obj[key]),
        }),
        {}
      );
    }
    return obj;
  };

  confirmEmailNotifiaction() {
    this.confirmEmail = true;
  }

  sendEmailNotification() {
    if (this.receiptID != "") {
      this.confirmEmail = false;
      this.txnService.emailNotification(this.receiptID).then((res) => {
        this.toast.handleResponse(res);
        this.clearCheckOutScreen();
      });
    } else {
      this.toast.showInfo("Please choose any order");
      this.confirmEmail = false;
    }
  }
}
