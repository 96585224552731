
import { Options, Vue } from "vue-class-component";
import Footer from "../../components/Footer.vue";
import Header from "../../components/Header.vue";
import { useStore } from "../../store";

@Options({
  components: {
    Header,
    Footer,
  },
})
export default class AppShell extends Vue {
  private store = useStore();
  get progressBar() {
    return this.store.getters.getProgressBar;
  }
}
